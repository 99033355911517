import Rails from '@rails/ujs'

const jquery = require('jquery')

window.jQuery = jquery
window.$ = jquery


require('../inspinia')
require('../main/scripts/users')
require('../main/scripts/password_strength')
require('../main/scripts/uploader')
require('../main/scripts/basic_confirmation')
// Require new confirm stuff here too

Rails.start()
