$(document).on 'change', '.image-type-radio', (event) ->
  setSelectedImageType(event.target)

$(document).on 'change', '#tenant_image', (event) ->
  upload_new_image(event.target)
  $('.delete-uploaded-image').show()

$(document).on 'click', '.delete-uploaded-image', (event) ->
  $('.js_image').attr('src', $('#default_image_src').val())
  $('.delete-uploaded-image').hide()
  $("#tenant_image").val(null);

$(document).on 'click', '.delete-tenant-image', (event) ->
  $('.js_image').attr('src', $('#orasi_image_src').val())
  $('.delete-tenant-image').hide()
  $("#tenant_image").val(null);
  $('#tenant_remove_image').attr('checked', true)


setSelectedImageType = (_element) ->
  el = $(_element)
  $('.image-type-cell').removeClass('selected')
  el.closest('.image-type-cell').addClass('selected')

upload_new_image = (input) ->
  if (input.files && input.files[0])
    if input.files[0].type.includes('image/')
      reader = new FileReader()

      reader.onload = (e) ->
        $('.js_image').attr('src', e.target.result)
        $('#tenant_remove_image').attr('checked', false)


      reader.readAsDataURL(input.files[0])
    else
      $('.js_image').attr('src', '')
