export class PasswordGenerator
  call: ->
    letters = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'
    randomIndex = -> Math.round((Math.pow(10, 16) * Math.random())) % (letters.length - 1)

    password = ''
    dashOffset = 0

    while password.length < 19 && dashOffset += 1
      password += letters[randomIndex()]

      if dashOffset == 4 && password.length != 19
        password += '-'
        dashOffset = 0

    password

export class UserModel
  constructor: (form) ->
    @form = $(form)
    @showPasswordButton = @form.find('#show_password')

    @showPasswordButton.click @onShowPasswordClick
    @form.find('#generate_password').click @generatePassword

  onShowPasswordClick: =>
    $passwordInput = @form.find('#user_password, #user_password_confirmation')

    if $passwordInput.attr('type') == 'password'
      $passwordInput.attr('type', 'text')
      @showPasswordButton.text('Hide Password')

    else if $passwordInput.attr('type') == 'text'
      $passwordInput.attr('type', 'password')
      @showPasswordButton.text('Show Password')

  generatePassword: =>
    password = new PasswordGenerator().call()
    @form.find('#user_password, #user_password_confirmation').val(password)
    @form.find('#user_password, #user_password_confirmation')[0].dispatchEvent(new Event('input', {bubbles: true,cancelable: true}))

$.fn.extend(userModel: -> new UserModel(@))


