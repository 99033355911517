import '../images/Orasilabs-main.png'
import './images/landing/shattered.png';
import './images/landing/word_map.png';
import './images/patterns/header-profile-skin-1.png';
import './images/patterns/header-profile-skin-2.png';
import './images/patterns/header-profile-skin-3.png';
import './images/patterns/header-profile.png';
import './images/patterns/otis_redding.png';
import './images/patterns/shattered.png';

import './styles/app.scss';

import './scripts/inspinia';

