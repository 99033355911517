const passwordStrength = require('zxcvbn')

const strength = {
  0: 'Very Weak',
  1: 'Weak',
  2: 'Ok',
  3: 'Strong',
  4: 'Very Strong'
}

function setStrengthClass(element, strength) {
  element.classList.remove('strength-0', 'strength-1', 'strength-2', 'strength-3', 'strength-4')
  element.classList.add(`strength-${strength}`)
}

document.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.getElementById('password-strength-display')
  if (!wrapper) return

  $('[data-toggle="tooltip"]').tooltip()

  const password = document.getElementById('user_password') ?
    document.getElementById('user_password') :
    document.getElementById('admin_system_admin_password')
  if (!password) return

  const passwordConfirmation = document.getElementById('user_password_confirmation') ?
    document.getElementById('user_password_confirmation') :
    document.getElementById('admin_system_admin_password_confirmation')
  if (!passwordConfirmation) return

  const meter = document.getElementById('password-strength-meter')
  const meterText = document.getElementById('password-strength-text')

  if (password && passwordConfirmation) {
    password.addEventListener('input', () => {
      const val = password.value
      const result = passwordStrength(val)

      meter.value = result.score

      if (val !== '') {
        wrapper.classList.remove('no-text')
        meterText.innerHTML = `${strength[result.score]}`
      } else {
        wrapper.classList.add('no-text')
        meterText.innerHTML = ''
      }
      setStrengthClass(wrapper, result.score)
    })
  }
})
