import fscreen from 'fscreen'
import swal2 from 'sweetalert2'


export default class Confirmer {
  constructor(type, title, message, options = {}) {
    this.type = type
    this.title = title
    this.message = message
    this.confirmButtonClass = options.confirmButtonClass || 'btn btn-primary'
    this.cancelButtonClass = options.cancelButtonClass || 'btn btn-default'
    this.showCancelButton = typeof options.showCancelButton === 'undefined' ? true : options.showCancelButton
    this.confirmButtonText = options.confirmButtonText || 'Continue'
    this.cancelButtonText = options.cancelButtonText || 'Cancel'
    this.input = options.input || null
    this.html = options.html || false
    this.inputOptions = options.inputOptions || null
    this.inputValue = options.inputValue || null
    this.inputPlaceholder = options.inputPlaceholder || null
    this.footer = options.footer || null
    this.preConfirm = options.preConfirm || null
    this.closeButton = options.showCloseButton || null

    const swalOptions = {
      customClass:       {
        confirmButton: this.confirmButtonClass,
        cancelButton:  this.cancelButtonClass
      },
      buttonsStyling:    false,
      title:             this.title,
      input:             this.input,
      inputPlaceholder:  this.inputPlaceholder,
      inputOptions:      this.inputOptions,
      inputValue:        this.inputValue,
      footer:            this.footer,
      type:              this.type,
      showCancelButton:  this.showCancelButton,
      cancelButtonText:  this.cancelButtonText,
      confirmButtonText: this.confirmButtonText,
      preConfirm:        this.preConfirm,
      showCloseButton:   this.closeButton
    }

    if (this.html) {
      swalOptions.html = this.message
    } else {
      swalOptions.text = this.message
    }
    this.swal = swal2.mixin(swalOptions)
  }

  confirm(action = null, allowReject = true) {
    const target = fscreen.fullscreenElement ? fscreen.fullscreenElement : document.body
    return new Promise((resolve, reject) => {
      this.swal.fire({ target }).then((result) => {
        if (result.value) {
          if (action) action()
          resolve(result)
        } else {
          if (allowReject) {
            reject(result)
          }
        }
      })
    })
  }
}
