import Rails from "@rails/ujs";
import Confirmer from '@utils/confirm.js'

const DEFAULT_OPTIONS = {
  confirmButtonClass: 'btn btn-danger',
  html: true
}


Rails.confirm = (message, element) => {
  const confirmer = new Confirmer('question', 'Are you sure?', message, DEFAULT_OPTIONS)
  confirmer.confirm().then(()=> {
    const old = Rails.confirm
    Rails.confirm = () => true
    element.click()
    // return true
    Rails.confirm = old
  }).catch((event) => {
    // console.trace('error', event)
    return false
  })

}

window.confirm =  async (message, options = DEFAULT_OPTIONS) => {
  const confirmer = new Confirmer('question', 'Are you sure?', message, options)
  await confirmer.confirm()
  return true
}
